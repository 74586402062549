*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    margin: 0;
    padding: 0;
    background: whitesmoke;
    overflow-x: hidden;
}
html { overflow-x: hidden; }
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.h-full{
    min-height: 70vh;
}

.h-full-index {
    min-height: 80vh;
    height: auto;
}
.form-signin{
    padding: 10%;
    background: rgb(210, 235, 255);
    border-radius: 13px;
}

.form-signin h2{
    letter-spacing: 3px;
    font-weight: bold;
    
}

.alert-pass{
font-size: 13px;
text-align: center;
color: red;
}


.navbar-custom {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px ;
    border: none;
    background: white;
}

.navbar-custom ul{
    list-style: none;
}

.navbar-custom ul li
{
    display: inline-block;
    padding: 0px 20px;
}
.navLink{

    text-decoration: none;
    color: black;
    
}

.navbar-custom a{
    text-decoration: none;
    cursor: pointer;
    transition: .5s;
}

.navbar-custom ul{
    padding-top: 10px;
}
.navbar-custom a:hover{
  color: rgb(100, 100, 100);
}

.navbar-brand-custom
{
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    color: black;
    letter-spacing: 2px;
}

.create-perfil{
    background: rgb(217, 243, 217);
    padding: 13%;
    border-radius: 13px;
    
}

.create-perfil span{
    width: 90px;
}

.create-perfil label{
    width: 90px;
}

.card{
    width: 33rem;
    border-radius: 33px;
    box-shadow: 1px 10px 13px 1px rgba(0, 0, 0, 0.2);
}

.card img{
    border-radius: 50%;
    width: auto;
    max-width: 200px;
    height: auto;
    box-shadow: 1px 10px 13px 1px rgba(0, 0, 0, 0.2);
}
.datos-perfil{
    font-weight: bold;
    margin-bottom: 0;
}

.buscador{
    padding: 13%;
    padding-bottom: 1%;
}

.food-list{
    padding: 3%;
    border-radius: 13px;
background: whitesmoke;
}

.foodName{
    font-weight: bold;
}

.foodMerch{
    text-align: center;
    margin-top: 0;
    color: gray;
}

.alimento-input{
    width: 100px;
    border-radius: 33px;
    border: none;
    background: whitesmoke;
}

.alimento-input-name{
    width: 70%;
    border-radius: 33px;
    border: none;
    background: whitesmoke;
}

.box-food{
    
    height: 100%;
    max-height: 500px;
    width: auto;

}

.box-addfood{
    padding-top: 1%;
    height: 100%;
    max-height: 600px;
    width: auto;

}

.fecha-index{
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.btn-alimentos{
    padding-bottom: 3%;
}

.main-nav-active{
    color:#F2AA4CFF;
}


.footerinfo{
    margin-top: 5%;
    padding: 50px;
    border: none;
    background: white;

    bottom: 0; 
   
}
.info{
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrap-content{
    flex: 1;
}

.login-arreglos{
    padding-top: 130px;
    padding-bottom: 130px
}

.dropitems{
    display: flex;
    flex-direction: column;
    margin: auto;
  position: absolute;
  top: 130%;
  z-index: 100;
    background: whitesmoke;
    padding: 13px;
    border-radius: 13px;
    box-shadow: 1px 10px 13px 1px rgba(0, 0, 0, 0.2);
}

.drop-url{
    cursor: pointer;
    color: black;
}


.shakeLogo{
animation: shake  5s infinite;
}

@keyframes shake {

    0%{
        transform: translate(0);
    }
    20%,
    40%,
    60%,
    80%{
        transform: translate(0);
    }
    10%,
    30%,
   
    50%{
        transform: translate(10px);
    }
    70%,
    100%{
        transform: translate(0);
    }
}
.info-dop{
    margin-top: 13%;
}
.info-dop h1{
    text-align: center;
    font-size: 90px;
    font-weight: bold
}

.info-dop h2{
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: rgb(97, 97, 97);
}

.info-dop-text{
    text-align: justify;
    padding: 3%;
    padding-left: 16%;
    padding-right: 16%;
    letter-spacing: 1px;
    font-size: 20px;
   
}
.h-fix{
    height: 5vh;
    margin-bottom: 20px;
}

.caratula img{
   padding-top: 13%;
}
.words{
    overflow: hidden;
}
.wrapper{
    box-sizing: content-box;
    height: 70px;
    
    display: flex;
   justify-content: center;
   margin-left: -1%;
}
.words span{
    text-align: center;
    display: block;
    padding: 7px;
    height: 100%;
   font-weight: bold;
    font-size: 40px;
   
    animation: spin_words 5s infinite;
}

@keyframes spin_words {
    0%{
        transform: translateY(-122%);
    }
    10%{
        transform: translateY(-122%);
    }
    25%{
        transform: translateY(-100%);
    }
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
    85%{
        transform: translateY(-412%);
    }
    100%{
        transform: translateY(-400%);
    }
    
}

.font-h{
    font-size: 20px;
}